*,
*:before,
*:after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

html,
body {
    height: 100%;
}

body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

input,
button,
textarea,
select {
    font: inherit;
    border-radius: 0;
    appearance: none;
}

textarea {
    white-space: revert;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

ol,
ul,
menu {
    list-style: none;
}
