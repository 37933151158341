#app {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include breakpoint(tabletLandscape) {
        flex-direction: row;
    }
}

.video-container {
    flex: 1;

    @include breakpoint(tabletLandscape) {
        width: 50%;
    }
    
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-container {
    background-color: #C2802F;
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
    gap: 40px 0;
    justify-content: center;
    align-items: center;
    animation: change-background 25s linear infinite;

    width: 100%;
    height: 100%;

    // @include breakpoint(tabletLandscape) {
    //     width: 50%;
    // }
}

.logo {
    width: 158px;

    @include breakpoint(tabletLandscape) {
        width: 240px;
    }
}

.text {
    color: #fff;
    text-align: center;
    font-size: rem(12px);
    letter-spacing: 0.0625rem;

    @include breakpoint(tabletLandscape) {
        font-size: rem(14px);
        width: 100%;
        max-width: 480px;
    }
}

.comingsoon {
    color: #fff;
    font-family: "Oxygen Mono", monospace;
    font-size: rem(14px);
    letter-spacing: 0.0625rem;

    @include breakpoint(tabletLandscape) {
        font-size: rem(16px);
    }
}

@keyframes change-background {
    0% {
      background: #C2802F;
    }
    25% {
      background: #7A5873;
    }
    50% {
      background: #723644;
    }
    75% {
        background: #3E0307;
    }  
    100% {
      background: #C2802F;
    }
  }