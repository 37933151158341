$black: #2D2926;
$white: #fff;
$turqoise: #00B9B4;
$beige: #F1F1DE;
$orange: #EF9600;
$yellow: #F9E547;
$red: #DA291C;
$pink: #F1A7DC;
$purple: #B14EB5;
$brown: #AA582F;
$green: #009F4D;
$blue: #9BD3DD;
$line-color: rgba(#2D2926, 0.1);

:root {
    --max-columns: 16;
    --max-width: 1920;
    --z-preloader: 9999;
    --z-menu: 9000;
    --z-bubble: 8000;
    --z-header: 100;
    --mouse-x: 0px;
    --mouse-y: 0px;
    --logo-color: #F1F1DE;
}

$font-script: 'Pardesi', 'sans-serif';
$font-display: 'Block Gothic', 'sans-serif';
$font-body: 'Silka', 'sans-serif';

