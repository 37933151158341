@mixin placeholder {
    &::-webkit-input-placeholder {
        color: #636166;
        opacity: 1;
    }

    &::-moz-placeholder {
        color: #636166;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color: #636166;
        opacity: 1;
    }

    &:-moz-placeholder {
        color: #636166;
        opacity: 1;
    }
}

@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin dark-selection {
    ::selection {
        background: var(--color-black);
        color: var(--color-white);
    }

    ::-moz-selection {
        background: var(--color-black);
        color: var(--color-white);
    }
}

@mixin light-selection {
    ::selection {
        background: var(--color-white);
        color: var(--color-black);
    }

    ::-moz-selection {
        background: var(--color-white);
        color: var(--color-black);
    }
}

@mixin canhover {
    @media (hover: hover) {
        @content;
    }
}

/**
* This function is for the use of aspect ratio within the project.
* Since the support for aspect ratio isn't the best (only from
* iOS15 for example) we need to provide a fallback.
*
* Even though the browsers we support in most projects are the last 2 versions
* let's make sure we get the most out of this handy css property.
*
* usage: @include aspect-ratio(4 / 3)
*/
@mixin aspect-ratio($width, $height) {
    aspect-ratio: #{$width} / #{$height};

    @supports not (aspect-ratio: #{$width} / #{$height}) {
        height: 0;
        padding-top: calc($height / $width * 100%);
        overflow: hidden;
    }
}

@mixin container($columns) {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    width: calc($columns / var(--max-columns) * 100%);
    max-width: calc((var(--max-width) / var(--max-columns)) * $columns * 1px);
}